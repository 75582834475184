import styled from '@emotion/styled';
import { AppBar, Box, Button, Divider } from '@mui/material';
import FooterBg from './../assets/FooterBg.png';
import BackgroundImg from './../assets/BackgroundImg.png'
import HeroBg from './../assets/HeroBg.png'

export const FooterContainer = styled(Box)({
    backgroundImage: `url(${FooterBg})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 500,
    position: 'relative',
    "@media (max-width:600px)": {
        minHeight: 375,
    }
});

export const FooterNavbar = styled(AppBar)({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: 'white !important',
    
});

export const FooterButtons = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

export const NavLink = styled(Button)({
    color: 'white',
    textTransform: 'none',
    fontSize: 18,
    borderRadius: 0,
    '&:hover':{
        borderBottom: '1px solid #ae0f7d'
    },
});

export const VerticalDivider = styled(Divider)({
    backgroundColor: 'white',
    width: '1px',
    height: 24,
    margin: '0 16px',
});

export const Logo = styled(Box)({
    position: 'absolute',
    top: '-100px'
})

export const MainContainer = styled(Box)({
    backgroundImage: `url(${BackgroundImg})`,
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  });

export  const HeroSection = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${HeroBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    paddingBottom: '50px',
    "@media (max-width:600px)": {
        paddingBottom: '250px'
    }
}));