import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import React from 'react'
import LaunchBg from './../../../../assets/LaunchBg.png';


const LaunchedItem = styled(Box)({
    backgroundImage: `url(${LaunchBg})`,
    backgroundRepeat: 'repeat-x',
    backgroundSize: 'auto 100%',
    minHeight: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: 'center'
})

const LaunchedText = styled(Typography)({
    fontSize: '98px',
    fontWeight: 700,
    color: 'white',
    "@media (max-width:600px)": {
      fontSize: '65px',
      textAlign: 'center',
  }
})


export default function Launched() {
  return (
    <LaunchedItem>
        <LaunchedText variant='h2'>
             Newly Launched
        </LaunchedText>
    </LaunchedItem>
  )
}
