import { createTheme } from "@mui/material";

export const Theme = createTheme({
    typography: {
        fontFamily: 'Poppins',        
    },
    palette: {
        primary: {
            main: '#95177b',
            dark: '#002884',
            contrastText: '#fff',
        },
     
    },
    customWidth: '1055px',
});
