import React from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Theme } from '../../../../theme/Theme';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import styled from '@emotion/styled';

const RoundedButton = styled(Button)({
  marginTop: '72px',
  maxWidth: 'fit-content',
  textTransform: 'none',
  fontSize: '18px',
  lineHeight: '28px',
  boxShadow: 'none',
  borderTopLeftRadius: 25,
  borderTopRightRadius: 25,
  borderBottomLeftRadius: 25,
  borderBottomRightRadius: 25,
  '&:hover': {
    backgroundColor: '#6e0959',
  },
  "@media (max-width:414px)": {
    marginTop: '35px',         
  }
});


export default function Cakecontainer({parentData}) {
  console.log(parentData)
  return (
    <Container>
        {parentData.length && parentData?.map((cakeItem, index) => (
          <Grid container spacing={2} key={index} my={20}>
            {window.innerWidth <= 600 ? (
              <>
                <Grid item md={6}>
                  <img src={cakeItem.ItemImage} alt="cake" />
                </Grid>
                <Grid item md={6}>
                <Typography variant='h4' fontWeight={700} color={Theme.palette.primary.main} sx={{
                    fontSize: '57.88px',
                    "@media (max-width:414px)": {
                        fontSize: '35px',
                    }
                  }}>{cakeItem.ItemTitle}</Typography>
                  <Typography variant='body1' fontSize={18} color={"#4d4d4f"} lineHeight={"26pt"}>{cakeItem.ItemDescription}</Typography>
                  <RoundedButton endIcon={<ArrowForwardIosRoundedIcon />} variant='contained' component={Link} to={cakeItem.ButtonUrl}>{cakeItem.ButtonText}</RoundedButton>
                </Grid>
              </>
            ) : (
              
              index % 2 === 0 ? (
                <>
                  <Grid item md={6}>
                    <img src={cakeItem.ItemImage} alt="cake" />
                  </Grid>
                  <Grid item md={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Typography variant='h4' fontSize={57.88} fontWeight={700} color={Theme.palette.primary.main}>{cakeItem.ItemTitle}</Typography>
                    <Typography variant='body1' fontSize={18} color={"#4d4d4f"} lineHeight={"26pt"}>{cakeItem.ItemDescription}</Typography>
                    <RoundedButton endIcon={<ArrowForwardIosRoundedIcon />} variant='contained' component={Link} to={cakeItem.ButtonUrl}>{cakeItem.ButtonText}</RoundedButton>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Typography variant='h4' fontSize={57.88} fontWeight={700} color={Theme.palette.primary.main}>{cakeItem.ItemTitle}</Typography>
                    <Typography variant='body1' fontSize={18} color={"#4d4d4f"} lineHeight={"26pt"}>{cakeItem.ItemDescription}</Typography>
                    <RoundedButton endIcon={<ArrowForwardIosRoundedIcon />} variant='contained' component={Link} to={cakeItem.ButtonUrl}>{cakeItem.ButtonText}</RoundedButton>
                  </Grid>
                  <Grid item md={6}>
                    <img src={cakeItem.ItemImage} alt="cake" />
                  </Grid>
                </>
              )
            )}
          </Grid>
        ))}
      </Container>
  )
}
