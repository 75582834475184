import { Box, IconButton, Toolbar, Typography } from '@mui/material';
import React from 'react';
import FooterLogo from './../../assets/FooterLogo.png';
import FooterItems from './FooterItems';
import { Link } from 'react-router-dom';
import { FooterContainer, FooterNavbar, FooterButtons, NavLink, VerticalDivider, Logo } from './../../theme/Style';
import facebook from './../../assets/facebook.png';
import insta from './../../assets/insta.png';

export default function Footer() {
  const FooterItem = FooterItems();

  return (
    <FooterContainer sx={{ marginTop: 25 }}>
      <Logo>
        <img
          src={FooterLogo}
          alt="Footer Logo"
          className='footerLogo'
          style={{
            maxWidth: '100%',
            "@media (max-width: 420px)": {
              maxWidth: '50%',
            },
          }}
        />
      </Logo>
      <Box
        sx={{
          marginTop: 15,
          display: 'flex',
         
        }}
      >
        <IconButton sx={{ color: 'white', margin: 1 }}>
          <img src={facebook} alt="" />
        </IconButton>
        <IconButton sx={{ color: 'white', margin: 1 }}>
          <img src={insta} alt="" />
        </IconButton>
      </Box>


      <Box sx={{
        "@media (max-width:600px)": {
            display: 'none'
        }
      }}>
        <FooterNavbar position='static'>
          <Toolbar>
            {FooterItem.map((item, index) => (
              <FooterButtons key={index}>
                <NavLink variant='text' component={Link} to={item.url}>
                  {item.title}
                </NavLink>
                {index !== FooterItem.length - 1 && <VerticalDivider />}
              </FooterButtons>
            ))}
          </Toolbar>
        </FooterNavbar>
      </Box>

      <Box sx={{
        "@media (min-width:600px)": {
            display: 'none'
        }
      }}>
      <Box>
        <FooterNavbar position='static'>
          <Toolbar>
            {FooterItem.slice(0, 3).map((item, index) => (
              <FooterButtons key={index}>
                <NavLink variant='text' component={Link} to={item.url}>
                  {item.title}
                </NavLink>
                {index !== 2 && <VerticalDivider />}
              </FooterButtons>
            ))}
          </Toolbar>
        </FooterNavbar>
      </Box>
      <Box>
        <FooterNavbar position='static'>
          <Toolbar>
            {FooterItem.slice(3).map((item, index) => (
              <FooterButtons key={index}>
                <NavLink variant='text' component={Link} to={item.url}>
                  {item.title}
                </NavLink>
                {index !== FooterItem.slice(3).length - 1 && <VerticalDivider />}
              </FooterButtons>
            ))}
          </Toolbar>
        </FooterNavbar>
      </Box>
    </Box>          
      
      <Box>
        <Typography variant='body2' fontSize={14} color={'#fff'}>
          <p className='copyright'>2023. mio amore. All Rights Reserved.</p>
        </Typography>
      </Box>
    </FooterContainer>
  );
}



