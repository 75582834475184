import React from 'react';
import { VarietyItems, VarietyItemsTwo } from './VarietyItems';
import { Box} from '@mui/material';
import Launched from '../launched/Launched';
import Cakecontainer from './Cakecontainer';


export default function VarietyCake() {
  const VarityItem = VarietyItems();
  const VarityItemTwo = VarietyItemsTwo();
  
  return (
    <Box>
      <Cakecontainer parentData={VarityItem}/>
      <Launched />
      <Cakecontainer parentData={VarityItemTwo}/>
    </Box>
  );
}
