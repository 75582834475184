import React from 'react'; // Make sure to import React if it's not already imported.
import party from './../../assets/party.svg';
import calendar from './../../assets/calendar.svg';
import rings from './../../assets/rings.svg';
import teddy from './../../assets/teddy.svg';

export function ProductCategory() {
  const Items = [
    {
      category: "Birthday Cake",
      icon: <img src={party} alt="Party" />,
      subCategory: [
        { id: 27, name: "1/2 Birthdays" },
        { id: 28, name: "Number Cakes" },
        { id: 29, name: "Teenage Birthdays" },
        { id: 30, name: "Boy's Birthdays" },
        { id: 31, name: "Girls' Birthdays" },
        { id: 32, name: "Wife's Birthdays" },
        { id: 33, name: "Husband's Birthdays" },
        
      ]
    },
    {
      category: "Anniversary Cake",
      icon: <img src={calendar} alt="Party" />,
      subCategory: [
        {id:34, name:"Landmark Anniversary"},
        {id:35, name:"Anniversary Surprise for Wife"},
        {id:36, name: "Anniversary Surprise for Husband"}
      ]
    },
    {
      category: "Wedding Cakes",
      icon: <img src={rings} alt="Party" />,
      subCategory: [
        {id:37, name:"2 Tier Cakes"},
        {id:38, name: "3 Tier Cakes"},
        {id:39, name:"Bengali Wedding Cakes"},
        {id:40, name: "Floral Designed Cakes"},
      ]
    },
    {
      category: "Kids Special",
      icon: <img src={teddy} alt="Kids Special" />,
      subCategory: [
        {id:41, name:"Sub category 1"},
        {id:42, name:"Sub category 2"},
        {id:43, name:"Sub category 3"},
      ]
    },
  ];

  return Items;
}
