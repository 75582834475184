import BirthdayCake from './../../../../assets/BirthdayCake.png'
import WeddingCake from './../../../../assets/WeddignCake.png'
import AnniverseryCake from './../../../../assets/AnniverseryCake.png'

export const VarietyItems =()=>{
    const items = [
        {   
            ItemImage: BirthdayCake,
            ItemTitle: "Birthday Cakes",
            ItemDescription: "Birthdays come only once a year! Whatever your age be, you deserve a big celebration involving a delish cake. These could be customized to your preferences.",
            ButtonText: "See More",
            ButtonUrl: "/products/23"
        },
        {   
            ItemImage: WeddingCake,
            ItemTitle: "Wedding Cakes",
            ItemDescription: "Seal your wedding vows with our exquisite wedding themed cakes. Big, delicious and elegant, these cakes will add a twist of extravagance to your special day.",
            ButtonText: "See More",
            ButtonUrl: "/products/24"
        },
        {   
            ItemImage: AnniverseryCake,
            ItemTitle: "Anniversary Cakes",
            ItemDescription: "Make a toast to your everlasting bond with some fluffy, moist deliciousness. Mio Amore Anniversary Cakes are no doubt great additions to the celebration of your special day.",
            ButtonText: "See More",
            ButtonUrl: "/products/25"
        },
        
        
    ]
    return items
}

export const VarietyItemsTwo =()=>{
    const items = [
        {   
            ItemImage: BirthdayCake,
            ItemTitle: "Birthday Cakes",
            ItemDescription: "Birthdays come only once a year! Whatever your age be, you deserve a big celebration involving a delish cake. These could be customized to your preferences.",
            ButtonText: "See More",
            ButtonUrl: "/products"
        },
        {   
            ItemImage: WeddingCake,
            ItemTitle: "Wedding Cakes",
            ItemDescription: "Seal your wedding vows with our exquisite wedding themed cakes. Big, delicious and elegant, these cakes will add a twist of extravagance to your special day.",
            ButtonText: "See More",
            ButtonUrl: "/products"
        },
        {   
            ItemImage: AnniverseryCake,
            ItemTitle: "Anniversary Cakes",
            ItemDescription: "Make a toast to your everlasting bond with some fluffy, moist deliciousness. Mio Amore Anniversary Cakes are no doubt great additions to the celebration of your special day.",
            ButtonText: "See More",
            ButtonUrl: "/products"
        },
        
        
    ]
    return items
}