import Home from './pages/home/Home';
import { Route, Routes} from 'react-router-dom';
import Products from './pages/products/Products';
import { MainContainer } from './theme/Style';



function App() {
  return (
    <MainContainer>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/:param" exact element={<Products />} />
        </Routes>
    </MainContainer>
  );
}

export default App;
;
