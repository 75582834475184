import React from 'react'
import Hero from './homeElements/hero/Hero'
import VarietyCake from './homeElements/varietyCake/VarietyCake'
import Footer from '../../components/footer/Footer'

export default function Home() {
  return (
    <>
    <Hero/>
    <VarietyCake/>
    <Footer/>
    </>
  )
}
