import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Logo from './../../../../assets/Logo.png'
import HeroCake from './../../../../assets/HeroCake.png'
import { Theme } from '../../../../theme/Theme'
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom'
import { HeroSection } from '../../../../theme/Style'



export default function Hero() {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
  return (
    <HeroSection className='heroSec'>
        <Container sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Grid container spacing={2} >
                {isSmallScreen ? (
                    <Grid item md={12} sx={{
                        height: 129,
                        width: 129,
                        margin: '0 auto',
                        marginBottom: 2,
                    }}>
                        <Box sx={{ paddingTop: '24px' }}>
                        <Link to={"/"} className='topLogo'>
                            <img src={Logo} alt="logo"/>
                            </Link>
                        </Box>
                    </Grid>
                ) : (
                    <Grid item md={12} sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                        <Box sx={{ paddingTop: '24px' }}>
                        <Link to={"/"}  className='topLogo'>
                            <img src={Logo} alt="logo"/>
                            </Link>
                        </Box>
                    </Grid>
                )}

                <Grid item md={11} sm={12}>
                    {isSmallScreen ? (
                        <Box className='welcome' 
                            sx={{ height: 'auto', display:'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={HeroCake} alt="" style={{ maxWidth: 250 }} />
                            <Typography variant='h4' color={Theme.palette.primary.main} fontSize={"45px"} fontWeight={700} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}>Customised Cakes for Special Moments</Typography>
                        </Box>
                    ) : (
                        <Box  className='welcome'
                            sx={{ height: 'auto', display: 'flex' }}>
                            <img src={HeroCake} alt="" style={{ maxWidth: 512 }} />
                            <Typography variant='h4' color={Theme.palette.primary.main} fontSize={"45px"} fontWeight={700} sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}>Customised Cakes for Special Moments</Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Container>
    </HeroSection>
  )
}
