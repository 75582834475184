
function FooterItems() {
    const items = [
        {
            title: "Home",
            url: "/"
        },
        {
            title: "About Us",
            url: ""
        },        
        {
            title: "Franchise Corner",
            url: ""
        },
        {
            title: "Contact Us",
            url: ""
        },
    ];

    return items;
}

export default FooterItems;


